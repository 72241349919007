export const userTypeDict = {
  0: '未付费',
  1: '服务期内',
  2: '服务过期',
};

export const statusDict = {
  0: '正常',
  1: '封禁',
};
